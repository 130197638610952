<div *ngIf="auth.user$ | async; then authenticated else guest">
    <!-- template will replace this div -->
</div>

<!-- User NOT logged in -->
<ng-template #guest>
    <h3>Howdy, GUEST</h3>
    <p>Login to get started...</p>

    <button (click)="auth.googleSignin()">
    <i class="fa fa-google"></i> Connect Google
</button>

</ng-template>


<!-- User logged in -->
<ng-template #authenticated>
    <div *ngIf="auth.user$ | async as user">
        <h3>You have been authorised, {{ user.displayName }}</h3>
        <img height="100px" [src]="user.photoURL">
        <p>UID: {{ user.uid }}</p>
        <button (click)="auth.signOut()">Logout</button>
        <button (click)="auth.home()">Get Started</button>
    </div>
</ng-template>