import { Component, OnInit } from '@angular/core';
import { FirebaseCloudMessagingService } from 'src/app/services/firebase-cloud-messaging.service';
import { NGXLogger } from 'ngx-logger';
import { Router } from '@angular/router';

@Component({
  selector: 'app-firebase-cloud',
  templateUrl: './firebase-cloud.component.html',
  styleUrls: ['./firebase-cloud.component.scss']
})
export class FirebaseCloudComponent implements OnInit {

  tokenString: string = "dQRWy-3idCc:APA91bEsgs-RSfKEZFAKZZvKgZfGeFhECHZOzNu4GGGifAIppbhuwXu5IjRyUVpFPwQXs_YDQ1696s1RzHym6NRmaHlv6jeg91s0BL3M_Y-UWai7rfzBgrKILUQ1dyX2Lfd8G5LxCNH3";
  message = {
    title: "How did we do?",
    // subtitle: "FCM Message Subtitle",
    body: "Click to learn more",
    // imageUrl: "https://www.wallsync.net/images/Header_Image.png",
    redirectUrl: "https://www.wallsync.net/documentation/"
  };

  constructor(
    private fcmService: FirebaseCloudMessagingService,
    private logger: NGXLogger,
    private router:Router
  ) { }

  ngOnInit(): void {}

  get tokens(){
    return this.tokenString.split("\n");
  }

  get jsonMsg() {
    return JSON.stringify(this.message)
  }

  get fcmJson() {
    return this.fcmService.messageJson(this.tokens[0],this.message.title,this.message.body,this.message.redirectUrl)
  }

  onSubmit() {
    this.fcmService.execute( this.message,this.tokens);
    this.router.navigateByUrl('/results');
  }

}
