import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { User } from 'src/app/model/user.model';
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { auth } from 'firebase/app';
@Injectable({
    providedIn: 'root'
})
export class AuthService {


    user$: Observable<User>;

    constructor(public afAuth: AngularFireAuth, private afs: AngularFirestore, public router: Router) {
        this.user$ = this.afAuth.authState.pipe(
            switchMap(user => {
                // Logged in
                if (user && this.isApprovedUser(user.email)) {
                    return this.afs.doc<User>(`users/${user.uid}`).valueChanges();
                }
                else {
                    // Logged out
                    return of(null);
                }
            })
        )
    }

    isApprovedUser(email: string) {
       return ['geoff@wallsync.net','lakshaybajaj.1996@gmail.com','lakshaybajaj1996@gmail.com','caroline@skoutli.com'].includes(email)
    }

    async googleSignin() {
        const provider = new auth.GoogleAuthProvider();
        const credential = await this.afAuth.auth.signInWithPopup(provider);
        return this.updateUserData(credential.user);
    }

    private updateUserData(user) {
        // Sets user data to firestore on login
        const userRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${user.uid}`);

        const data = {
            uid: user.uid,
            email: user.email,
            displayName: user.displayName,
            photoURL: user.photoURL
        }
        return userRef.set(data, { merge: true })
    }

    async signOut() {
        await this.afAuth.auth.signOut();
        this.router.navigate(['/']);
    }

    async home(){
        this.router.navigate(['/']);
    }
}
