import { Component, OnInit } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { FirebaseCloudMessagingService } from 'src/app/services/firebase-cloud-messaging.service';

@Component({
  selector: 'app-results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.scss']
})
export class ResultsComponent implements OnInit {

  results = [];

  constructor(
    private logger: NGXLogger,
    private fcmService: FirebaseCloudMessagingService
  ) { }

  ngOnInit(): void {
    this.results = this.fcmService.results;
  }

}
