import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NGXLogger } from 'ngx-logger';
import { retry, catchError } from 'rxjs/operators';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';

const CAPTUREAPP_KEY = "AAAA0l00kYM:APA91bEfqBftdNXaXVBMrzcxPg9TGzUvHqlG2XoDgqtxcMNU_0xYLWQFzFpG63ikX6PCONYZqrU_cfjmJqnyfRK525le1n84HtmqaFQW2oyrYc8PA52Gjq_Le9NMHyKwjd77iFTO-w1a";
const SMAPLYAPP_KEY = "AAAAlCzJ8g8:APA91bGXAZwOo0FdkUg9W57Jbc3itNiCVoQvAwEvCiZ8zDkHVDWqJ2kgZwdPRTgfZFATSkXqFR6d1zCMyjo728TkKWcQpxMxxVr2TGbBv295WROZUev1vKrnd-zPnDCG-gyiMLDwI536";

@Injectable({
    providedIn: 'root'
})
export class FirebaseCloudMessagingService {

    url = "https://fcm.googleapis.com/fcm/send";

    results: Array<{ tokenStart: string, result: string, data: string }> = [];


    constructor(
        private http: HttpClient,
        private logger: NGXLogger,
        private afs: AngularFirestore
    ) { }


    execute(message: any, tokens: string[]) {
        tokens.forEach(t => {
            let r: any = {};
            console.log(t)
            r.tokenStart = t.substring(0, 10);
            this.sendMessage(t, message).subscribe(
                (data: any) => { // Success
                    this.logger.info('response: ', JSON.stringify(data))
                    r.data = JSON.stringify(data);
                    r.result = (data.success > 0) ? 'SUCCESS' : 'UNREACHABLE';
                    this.results.push(r);
                },
                (error) => {
                    this.logger.error("Error: " + JSON.stringify(error));
                    r.data = JSON.stringify(error);
                    r.result = "EXCEPTION";
                    this.results.push(r);

                }
            );
        })
    }

    sendMessage(token, message) {
        let json = this.jsonFromMessageProperties(message, token);
        this.logger.info(`SEND FCM TO: ${token.substring(0, 10)} : ${json}}`);
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `key=${SMAPLYAPP_KEY}`
        });
        let options = { headers: headers };
        this.saveMessageLog()
        return this.http.post(this.url, json, options);
    }

    jsonFromMessageProperties(message: any, token: string) {
        return this.messageJson(token, message.title, message.body, message.redirectUrl)
    }

    messageJson(token, title, body, redirectUrl) {
        return `{
            "to": "${token}",
            "mutable_content": true,
            "notification": {
                "body": "${body}",
                "title": "${title}",
            },
            "data": {
                "web_url": "${redirectUrl}"
            }
        }`
    }


    private saveMessageLog() {

        const data = {
            blah:"blah"
        }

        return this.afs.collection(`messages`).add(data)

    }





}