import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FirebaseCloudComponent } from './components/firebase-cloud/firebase-cloud.component';
import { ResultsComponent } from './components/results/results.component';
import { AuthGuard } from './auth.guard';
import { UserProfileComponent } from './components/user-profile/user-profile.component';


const routes: Routes = [
    { path: '', component: FirebaseCloudComponent ,canActivate: [AuthGuard] },
    { path: 'results', component: ResultsComponent,canActivate: [AuthGuard]  },
    { path: 'user', component: UserProfileComponent }

];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
