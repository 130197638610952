<h3>WallSync Messaging Result</h3>
<table class="table">
    <thead>
        <tr>
            <th>Token</th>
            <th>Result</th>
            <th>Data</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let result of results">
            <td class="text-monospace small">{{result.tokenStart}}</td>
            <td>{{result.result}}</td>
            <td><span class="text-monospace small" style="font-size:10px">{{result.data}}</span></td>
        </tr>
    </tbody>
</table>

<br/>
<button onclick="window.location.href='/'">Send Another</button>