<div>
    <h4>WallSync Firebase Messaging Client</h4>

    <br />

    <p>Send a beautiful(ish) message</p>

    <div class="form-group">
        <label>Title</label><span class="label-help"> &nbsp;&nbsp;(the title of the message)</span>
        <input type="text" class="form-control" [(ngModel)]="message.title" [ngModelOptions]="{standalone: true}">
    </div>
    <!-- <div class="form-group">
        <label>Subtitle</label>
        <input type="text" class="form-control" [(ngModel)]="message.subtitle" [ngModelOptions]="{standalone: true}">
    </div> -->
    <div class="form-group">
        <label>Body</label><span class="label-help"> &nbsp;&nbsp;(the main text of the message)</span>
        <input type="text" class="form-control" [(ngModel)]="message.body" [ngModelOptions]="{standalone: true}">
    </div>
    <div class="form-group">
        <label>Redirect URL</label><span class="label-help"> &nbsp;&nbsp;(the URL that the users browser will be redirected to)</span>
        <input type="text" class="form-control" [(ngModel)]="message.redirectUrl" [ngModelOptions]="{standalone: true}">
    </div>

    <hr />

    <div class="form-group">
        <label>Tokens</label><span class="label-help"> &nbsp;&nbsp;(paste the list of device tokens that this message should go to)</span>
        <textarea style="font-size:9px" class="form-control" rows="6" [(ngModel)]="tokenString" [ngModelOptions]="{standalone: true}" ng-list="/\n/" ng-trim="true"></textarea>
    </div>
    <!-- <div class="form-group">
        <label>Image URL</label>
        <input type="text" class="form-control" [(ngModel)]="message.imageUrl" [ngModelOptions]="{standalone: true}">
    </div> -->
    <button type="submit" class="btn btn-primary" (click)="onSubmit()">Submit</button>
    <br />
    <br />
    <p>Technical Details</p>
    <p style="margin-top:10"><span style="font-weight:bold">FCM JSON:</span><br/><span class="text-monospace small" style="font-size:10px">{{fcmJson}}</span></p>
    <p style="margin-top:10"><span style="font-weight:bold">Tokens:</span><br/></p>
    <ol>
        <li *ngFor="let token of tokens"><span class="text-monospace small token">{{token}}</span></li>
    </ol>
</div>