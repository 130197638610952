import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms'
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';

import { FirebaseCloudComponent } from './components/firebase-cloud/firebase-cloud.component';
import { ResultsComponent } from './components/results/results.component';

import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { UserProfileComponent } from './components/user-profile/user-profile.component';



/**Firebase config */
var firebaseConfig = {
    apiKey: "AIzaSyD0ImwV2I2ccTc90bNmHDfpVf3P2-QL_jg",
    authDomain: "wallsync-messaging-client.firebaseapp.com",
    databaseURL: "https://wallsync-messaging-client.firebaseio.com",
    projectId: "wallsync-messaging-client",
    storageBucket: "wallsync-messaging-client.appspot.com",
    messagingSenderId: "1089847737864",
    appId: "1:1089847737864:web:ce8d316de79a659ea29bbd",
    measurementId: "G-PVBDY54PQD"
};


@NgModule({
    declarations: [
        AppComponent,
        FirebaseCloudComponent,
        ResultsComponent,
        UserProfileComponent
    ],
    imports: [
        BrowserModule,
        FormsModule,
        AppRoutingModule,
        HttpClientModule,
        LoggerModule.forRoot({
            // serverLoggingUrl: '/api/logs',
            level: NgxLoggerLevel.TRACE,
            serverLogLevel: NgxLoggerLevel.INFO,
            disableConsoleLogging: false
        }),
        AngularFireModule.initializeApp(firebaseConfig),
        AngularFireAuthModule,
        AngularFirestoreModule
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule { }